<!--
 * @Description: 短信配置
 * @Author: 琢磨先生
 * @Date: 2022-05-09 10:52:59
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-01-28 14:56:37
-->
<template>
  <el-scrollbar>
    <el-card class="box">
      <el-tabs v-model="activeName">
        <el-tab-pane
          v-for="item in list"
          :key="item.id"
          :label="item.supplier_name"
          :name="item.supplier"
        >
          <el-form ref="form" :model="item" label-width="100px">
            <el-form-item label="启用">
              <el-switch v-model="item.is_enabled"></el-switch>
            </el-form-item>
            <el-form-item label="AppKey">
              <el-col :span="6">
                <el-input
                  v-model="item.app_key"
                  placeholder="请输入短信app_key"
                ></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="AccessKey">
              <el-col :span="8">
                <el-input
                  v-model="item.access_key"
                  placeholder="请输入短信access_key"
                ></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="AccessSecret">
              <el-col :span="8">
                <el-input
                  v-model="item.access_secret"
                  placeholder="请输入短信access_secret"
                ></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="Domain">
              <el-col :span="8">
                <el-input
                  v-model="item.domain"
                  placeholder="请输入短信发送域名"
                ></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="通道号">
              <el-col :span="5">
                <el-input
                  v-model="item.channel"
                  placeholder="请输入通道号"
                ></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="签名">
              <el-col :span="4">
                <el-input
                  v-model="item.sign"
                  placeholder="请输入短信签名"
                ></el-input>
              </el-col>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                round
                :loading="submitting"
                @click="onSubmit(item)"
                >保存</el-button
              >
            </el-form-item>
          </el-form>
          <el-divider content-position="left"><b>模板</b></el-divider>
          <el-table :data="item.smsTemplates">
            <el-table-column prop="type_text" label="类型" width="140">
            </el-table-column>
            <el-table-column label="模板ID" width="280">
              <template #default="item">
                {{ item.row.id == templForm.id ? "" : item.row.templateid }}
                <el-input
                  type="textarea"
                  v-if="item.row.id == templForm.id"
                  v-model="templForm.templateid"
                  placeholder=""
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="模板内容" width="380">
              <template #default="item">
                {{
                  item.row.id == templForm.id ? "" : item.row.template_content
                }}
                <el-input
                  type="textarea"
                  v-if="item.row.id == templForm.id"
                  v-model="templForm.template_content"
                  placeholder=""
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="template_description" label="描述说明">
            </el-table-column>
            <el-table-column label="操作" width="120">
              <template #default="item">
                <el-button
                  type="primary"
                  link
                  @click="onUpdate(item.row)"
                  v-if="item.row.id != templForm.id"
                  >修改</el-button
                >
                <el-button
                  type="primary"
                  size="small"
                  v-if="item.row.id == templForm.id"
                  @click="onSaveTempl"
                  >确定</el-button
                >
                <el-button
                  type="info"
                  link
                  v-if="item.row.id == templForm.id"
                  @click="onCancel()"
                  >取消</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </el-scrollbar>
</template>
<script>
export default {
  data() {
    return {
      submitting: false,
      activeName: "",
      list: [],
      templForm: {
        id: 0,
        templateid: "",
        template_content: "",
      },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      this.$http.get("admin/v1/smsconfig").then((res) => {
        if (res.code == 0) {
          this.list = res.data;
          if (!this.activeName && res.data.length > 0) {
            this.activeName = res.data[0].supplier;
          }
        }
      });
    },
    onSubmit(item) {
      this.submitting = false;
      this.$http.post("admin/v1/smsconfig/edit", item).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: res.msg,
          });
        }
        this.submitting = false;
      });
    },
    /**
     * 点击修改模板
     */
    onUpdate(item) {
      this.templForm.id = item.id;
      this.templForm.templateid = item.templateid;
      this.templForm.template_content = item.template_content;
    },
    /**
     * 保存模板
     */
    onSaveTempl() {
      console.log(this.templForm);
      this.$http
        .post("admin/v1/smsconfig/template/edit", this.templForm)
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.loadData();
            this.onCancel();
          }
        });
    },
    /**
     * 取消模板编辑
     */
    onCancel() {
      this.templForm = {};
    },
  },
};
</script>